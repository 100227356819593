import React from "react"
import { Link, graphql } from "gatsby"
import getShareImage from "@jlengstorf/get-share-image"
import { Helmet } from "react-helmet"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/EmailSignup"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const longPostTitle =
      post.frontmatter.title.split(" ").length > 7 ? true : false
    let socialImage = ""
    if (longPostTitle) {
      socialImage = getShareImage({
        title: post.frontmatter.title,
        // tagline: post.tags.map(tag => `#${tag}`).join(" "),
        // tagline: post.frontmatter.description,
        cloudName: "broca",
        imagePublicID: "v1579989752/Social_Image_Template.png",
        titleFont: "Roboto Slab",
        // titleExtraConfig: "_bold", // optional - set title font weight to bold
        titleExtraConfig: "_line_spacing_-15_bold_text_align_center",
        // taglineFont: "lwj-tagline.otf",
        textLeftOffset: longPostTitle ? 700 : 625,
        textAreaWidth: 630,
        textColor: "434190",
        titleFontSize: 80,
        titleBottomOffset: longPostTitle ? 125 : 254,
      })
    } else {
      socialImage = getShareImage({
        title: post.frontmatter.title,
        // tagline: post.tags.map(tag => `#${tag}`).join(" "),
        tagline: post.frontmatter.description,
        cloudName: "broca",
        imagePublicID: "v1579989752/Social_Image_Template.png",
        titleFont: "Roboto Slab",
        // titleExtraConfig: "_bold", // optional - set title font weight to bold
        titleExtraConfig: "_line_spacing_-15_bold",
        taglineFont: "Lato",
        taglineExtraConfig: "_italic_bold",
        textLeftOffset: 625,
        textAreaWidth: 630,
        titleColor: "434190",
        taglineColor: "4c51bf",
        titleBottomOffset: 254,
        titleFontSize: 80,
        taglineFontSize: 45,
      })
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet>
          <meta property="og:image" content={socialImage} />
          <meta property="twitter:image" content={socialImage} />
          <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article>
          <header>
            <h1
              className="mt-5 font-black text-4xl leading-snug"
              style={{
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              className="-mt-1 mb-0 text-sm"
              style={{
                display: `block`,
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <section
            className="markdown"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr className="my-6" />
          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          <section className="my-8">
            <div className="flex flex-col flex-center items-center">
              <p className="font-semibold mb-3">
                I send updates on my progress every Saturday.
              </p>
              <EmailSignup></EmailSignup>
            </div>
          </section>
          <footer>
            <Bio />
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
